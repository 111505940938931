/* eslint-disable */
// this is an auto generated file. This will be overwritten
import gql from "graphql-tag";

export const getUserDetails = gql`
  query getUserDetails {
    getUserDetails {
      id
      email
      shopName
      shopType
      role
      randomId
      shopId
      outletShopId
      billPrefix
      location
    }
  }
`;

export const getItemsByType = gql`
  query getItemsByType(
    $type: String
    $status: String
    $shopId: String
    $nextToken: String
    $limit: Int
  ) {
    getItemsByType(
      type: $type
      status: $status
      shopId: $shopId
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        itemName
        itemDescription
        itemUOM
        type
        status
        componentList
        location
        address
        shippingAddress
        phoneNumber
        outletType
        randomId
        country
        currency
        shopName
        name
        notes
        deliveryNotes
        orderDetails
        totalAmount
        subTotal
        GST
        GSTAmount
        IGST
        IGSTAmount
        discountValue
        discountType
        discountAmount
        email
        addedDate
        time
        outletShopId
        stockReceived
        followUpStatus
        followUpLog
        followedUpDate
        followUpDate
        GSTNumber
        vendorId
        PO_ID
        addedBy
        billPrefix
        tinyURL
        incomingQuantity
        remainingQuantity
        soldQuantity
        lowStockValue
        itemId
        role
        calBasedOn
        unitPerPanel
        visitDate
        visitTime
        rejectReason
        paymentDueDate
        pincode
        estimatedDelivery
        deliveryExecutive
        paymentDetails
        quotationTrail
        group
        formula
        explaination
        example
        formulaList
        itemTags
        symbol
        shopType
        itemImages
        masterPP
        extraCharges
        quantityLog
        color
        outletSQFTMap
        action
        installmentAmount
        vendorName
        paymentMode
        paymentId
        invoiceId
        HSN
        profileList
        token
        tokenWeb
        category
        balance
      }
      nextToken
    }
  }
`;

export const getItemById = gql`
  query getItemById($id: String) {
    getItemById(id: $id) {
      id
      itemName
      itemDescription
      itemUOM
      type
      status
      componentList
      location
      address
      shippingAddress
      phoneNumber
      outletType
      randomId
      country
      currency
      shopName
      name
      notes
      deliveryNotes
      orderDetails
      totalAmount
      subTotal
      GST
      GSTAmount
      IGST
      IGSTAmount
      TDS
      TDSAmount
      discountValue
      discountType
      discountAmount
      email
      addedDate
      time
      outletShopId
      stockReceived
      followUpStatus
      followUpLog
      followedUpDate
      followUpDate
      GSTNumber
      vendorId
      PO_ID
      addedBy
      billPrefix
      tinyURL
      incomingQuantity
      remainingQuantity
      soldQuantity
      lowStockValue
      itemId
      calBasedOn
      unitPerPanel
      visitDate
      visitTime
      rejectReason
      paymentDueDate
      pincode
      estimatedDelivery
      deliveryExecutive
      paymentDetails
      quotationTrail
      role
      group
      formula
      explaination
      example
      formulaList
      workshopAddress
      PDFNotes
      paymentConditions
      tAndC
      itemTags
      symbol
      addedByOutletShopId
      addedByLocation
      assignToLocation
      assignToUser
      assignToUserId
      itemImages
      leadId
      masterPP
      invDate
      invGenerated
      invTinyURL
      leadSource
      itemCode
      DOB
      DOJ
      gender
      extraCharges
      quantityLog
      color
      driverDetails
      itemFile
      itemImagesInstall
      quotationId
      outletSQFTMap
      masterOrderId
      prodOrderId
      orderLocation
      bankDetails
      orderBy
      orderId
      complaint
      priority
      vendorName
      vendorId
      HSN
      shopId
      documents
      emgDetails
      bankDetailsMap
      from
      installDt
      holidayList
      salaryDetails
      salaryCredited
      profileList
      attndDetails
      earning
      deductions
      salary
      expCatList
      paymentMode
      date
      bankName
      paymentDone
      installmentCount
      installmentDone
      installmentLog
      paymentPending
      paymentStatus
      installmentAmount
      category
      state
      city
      bankBlnc
      balance
      outletBalance
    }
  }
`;

export const getItemsByOutlet = gql`
  query getItemsByOutlet(
    $type: String
    $status: String
    $outletShopId: String
    $nextToken: String
    $limit: Int
  ) {
    getItemsByOutlet(
      type: $type
      status: $status
      outletShopId: $outletShopId
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        itemName
        itemDescription
        itemUOM
        type
        status
        componentList
        location
        address
        shippingAddress
        phoneNumber
        outletType
        randomId
        country
        currency
        shopName
        name
        notes
        deliveryNotes
        orderDetails
        totalAmount
        subTotal
        GST
        GSTAmount
        IGST
        IGSTAmount
        discountValue
        discountType
        discountAmount
        email
        addedDate
        time
        outletShopId
        stockReceived
        followUpStatus
        followUpLog
        followedUpDate
        followUpDate
        GSTNumber
        vendorId
        PO_ID
        addedBy
        billPrefix
        tinyURL
        incomingQuantity
        remainingQuantity
        soldQuantity
        lowStockValue
        itemId
        calBasedOn
        unitPerPanel
        visitDate
        visitTime
        rejectReason
        paymentDueDate
        pincode
        estimatedDelivery
        deliveryExecutive
        paymentDetails
        quotationTrail
        role
        group
        formula
        explaination
        example
        formulaList
        itemTags
        symbol
        itemImages
        masterPP
        extraCharges
        quantityLog
        color
        updatedDate
        updatedTime
        rawMaterial
        DOB
        DOJ
        gender
        documents
        emgDetails
        bankDetailsMap
        salaryDetails
        category
        city
        state
      }
      nextToken
    }
  }
`;

export const getItemIdByType = gql`
  query getItemIdByType(
    $itemId: String
    $type: String
    $nextToken: String
    $limit: Int
  ) {
    getItemIdByType(
      itemId: $itemId
      type: $type
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        itemId
        incomingQuantity
        remainingQuantity
        soldQuantity
        lowStockValue
        outletShopId
        action
        from
        trackingID
        quantity
        count
        addedDate
        time
        quantityLog
        itemTags
        color
        rawMaterial
        addedDate
        time
      }
      nextToken
    }
  }
`;

export const getAllItemsByType = gql`
  query getAllItemsByType(
    $outletShopId: String
    $type: String
    $nextToken: String
    $limit: Int
  ) {
    getAllItemsByType(
      outletShopId: $outletShopId
      type: $type
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        name
        phoneNumber
        addedDate
        status
        randomId
        totalAmount
        time
        tinyURL
        followUpDate
        invDate
        assignToLocation
        leadSource
        addedByLocation
        orderId
        masterOrderId
        orderBy
        paymentDetails
        location
        orderLocation
        addedBy
        estimatedDelivery
        address
        assignToUser
        vendorName
        vendorId
        paymentStatus
        paymentPending
        installDt
        itemName
        itemDescription
        date
        PO_ID
        subTotal
        GSTAmount
        paymentDone
        paymentDueDate
        extraCharges
        IGSTAmount
        installmentCount
        installmentDone
        shippingAddress
      }
      nextToken
    }
  }
`;

export const getItemByTypeAndDate = gql`
  query getItemByTypeAndDate(
    $outletShopId: String
    $type: String
    $date1: String
    $date2: String
    $nextToken: String
    $limit: Int
  ) {
    getItemByTypeAndDate(
      outletShopId: $outletShopId
      type: $type
      date1: $date1
      date2: $date2
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        name
        phoneNumber
        addedDate
        status
        randomId
        totalAmount
        time
        tinyURL
        followUpDate
        leadSource
        estimatedDelivery
        orderLocation
        action
        quantity
        itemName
        itemId
        location
        vendorName
        date
        itemDescription
        orderDetails
        bankName
        type
        installmentAmount
        timestamp
        subTotal
        GSTAmount
        IGSTAmount
        PO_ID
        installDt
        shippingAddress
        masterOrderId
      }
      nextToken
    }
  }
`;

export const getAllItemsByShopType = gql`
  query getAllItemsByShopType(
    $type: String
    $shopId: String
    $nextToken: String
    $limit: Int
  ) {
    getAllItemsByShopType(
      type: $type
      shopId: $shopId
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        itemName
        itemDescription
        itemUOM
        type
        status
        componentList
        location
        address
        shippingAddress
        phoneNumber
        outletType
        randomId
        country
        currency
        shopName
        name
        notes
        deliveryNotes
        orderDetails
        totalAmount
        subTotal
        GST
        GSTAmount
        discountValue
        discountType
        discountAmount
        email
        addedDate
        time
        outletShopId
        stockReceived
        followUpStatus
        followUpLog
        followedUpDate
        followUpDate
        GSTNumber
        vendorId
        PO_ID
        addedBy
        billPrefix
        tinyURL
        incomingQuantity
        remainingQuantity
        soldQuantity
        lowStockValue
        itemId
        role
        calBasedOn
        unitPerPanel
        visitDate
        visitTime
        rejectReason
        paymentDueDate
        pincode
        estimatedDelivery
        deliveryExecutive
        paymentDetails
        quotationTrail
        group
        formula
        explaination
        example
        formulaList
        itemTags
        symbol
        shopType
        itemImages
        masterPP
        addedByLocation
        assignToLocation
        extraCharges
        quantityLog
        color
        leadSource
        assignToUser
        vendorName
        orderLocation
      }
      nextToken
    }
  }
`;

export const getAttndByYear = gql`
  query getAttndByYear($type: String, $empId: String, $year: String) {
    getAttndByYear(type: $type, empId: $empId, year: $year) {
      id
      year
      empId
      attendance
      name
    }
  }
`;

export const getPayroll = gql`
  query getPayroll($type: String, $month: String, $year: String) {
    getPayroll(type: $type, month: $month, year: $year) {
      id
      randomId
      name
      empId
    }
  }
`;

export const getItemByVendor = gql`
  query getItemByVendor(
    $type: String
    $status: String
    $outletShopId: String
    $vendorId: String
    $nextToken: String
    $limit: Int
  ) {
    getItemByVendor(
      type: $type
      status: $status
      outletShopId: $outletShopId
      vendorId: $vendorId
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        addedDate
        addedBy
        componentList
        type
        status
        randomId
        totalAmount
        time
        outletShopId
        paymentDueDate
        paymentPending
        paymentDone
        paymentStatus
        paymentMode
        paymentId
        installmentAmount
        date
        paymentType
      }
      nextToken
    }
  }
`;

export const getItemByOutletId = gql`
  query getItemByOutletId(
    $type: String
    $status: String
    $outletShopId: String
    $outletId: String
    $nextToken: String
    $limit: Int
  ) {
    getItemByOutletId(
      type: $type
      status: $status
      outletShopId: $outletShopId
      outletId: $outletId
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        addedDate
        addedBy
        componentList
        type
        status
        randomId
        totalAmount
        time
        outletShopId
        paymentDueDate
        paymentPending
        paymentDone
        paymentStatus
        paymentMode
        paymentId
        installmentAmount
        date
        paymentType
      }
      nextToken
    }
  }
`;

export const getItemByTypedate = gql`
  query getItemByTypedate(
    $outletShopId: String
    $type: String
    $date1: String
    $date2: String
    $nextToken: String
    $limit: Int
  ) {
    getItemByTypedate(
      outletShopId: $outletShopId
      type: $type
      date1: $date1
      date2: $date2
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        name
        phoneNumber
        addedDate
        status
        randomId
        totalAmount
        time
        tinyURL
        followUpDate
        leadSource
        estimatedDelivery
        orderLocation
        action
        quantity
        itemName
        itemId
        location
        vendorName
        itemDescription
        date
        paymentPending
        paymentDone
        paymentDueDate
        paymentStatus
        bankName
        type
        installmentAmount
        paymentMode
        subTotal
        GSTAmount
        IGSTAmount
        PO_ID
        balance
        state
        timestamp
      }
      nextToken
    }
  }
`;

export const getTypeAndDateShopId = gql`
  query getTypeAndDateShopId(
    $shopId: String
    $type: String
    $date1: String
    $date2: String
    $nextToken: String
    $limit: Int
  ) {
    getTypeAndDateShopId(
      shopId: $shopId
      type: $type
      date1: $date1
      date2: $date2
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        status
        leadSource
        randomId
        phoneNumber
        name
        addedDate
        assignToLocation
        totalAmount
        location
        orderLocation
        addedByLocation
        addedBy
      }
      nextToken
    }
  }
`;

export const getProdGroupReport = gql`
  query getProdGroupReport(
    $outletShopId: String
    $type: String
    $date1: String
    $date2: String
    $status1: String
    $status2: String
    $nextToken: String
    $limit: Int
  ) {
    getProdGroupReport(
      outletShopId: $outletShopId
      type: $type
      date1: $date1
      date2: $date2
      status1: $status1
      status2: $status2
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        orderDetails
      }
      nextToken
    }
  }
`;

export const getItemByTypeDateStatus = gql`
  query getItemByTypeDateStatus(
    $type: String
    $outletShopId: String
    $VOrOId: String
    $date: String
    $paymentStatus: String
    $nextToken: String
    $limit: Int
  ) {
    getItemByTypeDateStatus(
      type: $type
      outletShopId: $outletShopId
      VOrOId: $VOrOId
      date: $date
      paymentStatus: $paymentStatus
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        vendorName
        addedDate
        location
        randomId
        paymentDueDate
        paymentStatus
        paymentPending
        paymentDone
        totalAmount
        date
        PO_ID
      }
      nextToken
    }
  }
`;

export const getInvoiceDateOV = gql`
  query getInvoiceDateOV(
    $type: String
    $outletShopId: String
    $VOrOId: String
    $date1: String
    $date2: String
    $nextToken: String
    $limit: Int
  ) {
    getInvoiceDateOV(
      type: $type
      outletShopId: $outletShopId
      VOrOId: $VOrOId
      date1: $date1
      date2: $date2
      nextToken: $nextToken
      limit: $limit
    ) {
      billData {
        id
        vendorName
        addedDate
        location
        randomId
        paymentDueDate
        paymentStatus
        paymentPending
        paymentDone
        totalAmount
        date
        timestamp
        installmentAmount
        type
        category
        state
        PO_ID
        bankName
        city
        outletBalance
        type
      }
      nextToken
    }
  }
`;
