import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Divider,
  Card,
  Select,
} from "antd";
import { SearchOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { TextField, MenuItem, InputAdornment } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc, getAllItemsPagination10 } from "./functionCall";
const { Option } = Select;

function AddProductGroups(props) {
  const navigate = useNavigate();
  const [componentList, setComponentList] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddComponents, setModalAddComponents] = useState(false);
  const [term, setTerm] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [status, setStatus] = useState("");
  const statusArray = [
    "live",
    "In-Review",
    "Order Placed",
    "Delivered",
    "Rejected",
  ];
  let test = useParams();

  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      render: (quantity, record) => (
        <Input
          value={quantity}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "quantity", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "quantity", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
      render: (color, record) => (
        <Select
          style={{ width: "90%" }}
          onChange={(value) => {
            addValue(record.key, "color", value);
          }}
          value={color}
        >
          {props.common.colorArray
            .concat(props.common.meshColorArray)
            .map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      ellipsis: true,
      render: (notes, record) => (
        <Input
          value={notes}
          onChange={(e) => addValue(record.key, "notes", e.target.value)}
          maxLength={6}
        />
      ),
    },
    ...(test.test === "addNewPR"
      ? [
          {
            title: "Delete",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            render: (id, record) => (
              <Button
                type="primary"
                onClick={() => removeComponent(record.key)}
                danger={true}
                style={{ width: "90%" }}
              >
                Remove
              </Button>
            ),
          },
        ]
      : []),
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => addComponent(record)}
          style={{ width: "60%" }}
        >
          Add
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
          if (test.test != "addNewPR") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("PurchaseRequest")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setComponentList(JSON.parse(res.componentList));
        setStatus(res.status);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Component", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const addItem = async () => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10(
      "PurchaseRequest",
      outletShopId
    );
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "PR-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "PR-" + year + "-" + tempCounter;
    try {
      let PI = await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          componentList: JSON.stringify(componentList),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "PurchaseRequest",
        },
      });
      message.error("Purchase Request Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          status: status,
          componentList: JSON.stringify(componentList),
          updatedBy: updatedBy,
        },
      });
      message.error("Purchase Request updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (Object.keys(componentList).length == 0)
      return message.error("Please add item to the purchase request");

    let flag = false;
    Object.entries(componentList).map(([key, value]) => {
      if (value.quantity == 0) {
        flag = true;
        return message.error("Enter quantity for " + value.itemName);
      }
    });

    if (flag) return;

    setSpinnerState("true");

    if (test.test === "addNewPR") addItem();
    else editItem();
  };

  const addComponent = (item) => {
    let tempList = { ...componentList };
    let key = uuidv4();
    tempList[key] = {};
    tempList[key].id = item.id;
    tempList[key].key = key;
    tempList[key].itemName = item.itemName;
    tempList[key].itemUOM = item.itemUOM;
    tempList[key].quantity = 0;
    tempList[key].color = "Other";
    tempList[key].notes = "";
    setComponentList(tempList);
    setModalAddComponents(false);
  };

  const removeComponent = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    temp[key][value] = data;
    setComponentList(temp);
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1100px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 40,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div style={{ marginLeft: -20 }}>
          <h1 className="bill-heading">Purchase Request Details</h1>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24}>
            <Table
              dataSource={Object.values(componentList)}
              columns={columns}
              className="my-table"
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {test.test === "addNewPR" &&
            Object.keys(componentList).length == 0 && (
              <Col xs={24} md={12} lg={8}>
                <Button
                  className="button"
                  type="primary"
                  size="large"
                  disabled={spinnerState}
                  onClick={() => setModalAddComponents(true)}
                  style={{ marginTop: 20 }}
                >
                  <PlusOutlined /> Add Item
                </Button>
              </Col>
            )}
          {test.test != "addNewPR" && (
            <TextField
              select
              margin="normal"
              label="Select Status"
              variant="outlined"
              style={{
                width: "15%",
                backgroundColor: "#ffff",
                marginRight: 10,
              }}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              autoComplete="off"
            >
              <MenuItem key="" value="">
                Select Status
              </MenuItem>
              {statusArray.map((item) => (
                <MenuItem key={item} value={item}>
                  {item == "live" ? "New" : item}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Col xs={24} md={12} lg={8}>
            <Button
              className="button"
              type="primary"
              size="large"
              disabled={spinnerState}
              onClick={() => checkInput()}
              style={{ marginTop: 20 }}
            >
              {test.test === "addNewPR"
                ? "Add Purchase Request"
                : "Update Purchase Request"}
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        visible={modalAddComponents}
        width={"70%"}
        onCancel={() => setModalAddComponents(false)}
        footer={null}
        style={customStylesAddProducts}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        <div className="bill">
          <div className="bill-container">
            <TextField
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              size="small"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              style={{ width: "95%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />

            <Row>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                  pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={8} lg={6} offset={21}>
                <div className="discount">
                  <Button
                    type="primary"
                    onClick={() => setModalAddComponents(false)}
                  >
                    Add Item
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProductGroups);
