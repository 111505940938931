import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../styles/signIn.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Button, message, Input, Card } from "antd";

function ForgotPassword() {
  const navigate = useNavigate();
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {})
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
  }, []);

  const forgotPassword = async () => {
    try {
      await Auth.forgotPassword(username);
      console.log("Confirmation code sent to your email");
    } catch (error) {
      console.log("Error sending confirmation code", error);
    }
  };

  // Step 2: Confirm the new password
  const handleResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(username, confirmationCode, newPassword);
      console.log("Password reset successful");
    } catch (error) {
      console.log("Error resetting password", error);
    }
  };

  const checkInput = () => {
    if (username == "") return message.error("Please enter username");
    if (confirmationCode === "")
      return message.error("Please enter confirmationCode");
    if (newPassword === "") return message.error("Please enter new password");
    setSpinnerState(true);
    handleResetPassword();
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">Forgot Password</h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => forgotPassword()}
          >
            Send Code
          </Button>
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            Change Password
          </Button>
        </Col>
        <div className="divider"></div>
      </Row>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Username:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                maxLength={100}
              />
            </div>
          </Col>
        </Row>
      </Card>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Code:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Code"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                maxLength={100}
                type="password"
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                New Password:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
                maxLength={100}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
