import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Button } from "antd";
import { checkAccess } from "./AuthorizationService";
import { getItemsByShopTypeFunc } from "./functionCall";
import { CSVLink } from "react-csv";
import { TextField, MenuItem } from "@mui/material";

function ListST() {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [showCount, setShowCount] = useState({});
  const [status, setStatus] = useState("");
  const [shopId, setShopId] = useState("");

  const headers = [
    { label: "Id", key: "randomId" },
    { label: "Added Date", key: "addedDate" },
    { label: "Vendor Name", key: "vendorName" },
    { label: "Status", key: "status" },
    { label: "Delivery Date", key: "estimatedDelivery" },
  ];

  const Columns = [
    {
      title: "DC No.",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      render: (randomId, record) => (
        <a style={{ color: record.status == "Pending" ? "Red" : "Black" }}>
          {randomId}
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
    {
      title: "Delivery Date",
      dataIndex: "estimatedDelivery",
      key: "estimatedDelivery",
      ellipsis: true,
      render: (estimatedDelivery) =>
        estimatedDelivery
          ? moment(estimatedDelivery).format("DD MMM YY")
          : "NA",
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => navigate(`../AddCoating/${id}`)}>View</a>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Inventory")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByShopTypeFunc("SendCoating", shopId);
    if (status != "")
      allItems = allItems.filter((item) => item.status == status);
    setAllItems(allItems);

    let temp = {
      New: 0,
      Complete: 0,
      Pending: 0,
    };
    temp.New = allItems.filter((item) => item.status == "live").length;
    temp.Complete = allItems.filter((item) => item.status == "Complete").length;
    temp.Pending = allItems.filter((item) => item.status == "Pending").length;
    setShowCount(temp);
  };

  useEffect(() => {
    if (shopId != "") {
      setAllItems([]);
      getItemsByType(shopId);
    }
  }, [status]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <p>Select status</p>
          <TextField
            select
            margin="normal"
            variant="outlined"
            style={{ width: "30%" }}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            autoComplete="off"
          >
            <MenuItem key="" value="">
              Select Status
            </MenuItem>
            <MenuItem key="live" value="live">
              New
            </MenuItem>
            <MenuItem key="Pending" value="Pending">
              Pending
            </MenuItem>
            <MenuItem key="Complete" value="Complete">
              Complete
            </MenuItem>
          </TextField>
          <Button type="primary">
            <Link to="../AddCoating/addNewCoating">New Coating List</Link>
          </Button>
          {allItems.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={allItems}
                headers={headers}
                filename={"Send Coating.csv"}
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        <div style={{ paddingBottom: 10 }}>{allItems.length} Coating List</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          {Object.entries(showCount).map(([key, value]) => {
            return (
              <p style={{ fontWeight: "bold" }}>
                {key} : {value}
              </p>
            );
          })}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table columns={Columns} dataSource={allItems} />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListST);
