import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, DatePicker, Select, Button } from "antd";
import {
  getInvoiceDateOVPagination,
  getItemsByTypeFunc,
  getItemByTypeDateStatusPagination,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import InvoiceStmnt from "./genInvoiceStmnt";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function ReportExpense() {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletId, setOutletId] = useState("");
  const [allOutlets, setAllOutlet] = useState([]);
  const [location, setLocation] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [from, setFrom] = useState("");
  const [openingBlnc, setOpeningBlnc] = useState(0);
  const [closingBlnc, setClosingBlnc] = useState(0);
  const [displayBlnc, setDisplayBlnc] = useState(true);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const Columns = [
    {
      title: "Invoice Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (date) => moment(date).format("DD MMM YY"),
    },
    {
      title: "Description",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Number",
      dataIndex: "PO_ID",
      key: "PO_ID",
      ellipsis: true,
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      ellipsis: true,
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      ellipsis: true,
      render: (debit) => parseFloat(debit).toFixed(2),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      ellipsis: true,
      render: (credit) => parseFloat(credit).toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Statement")) navigate("/homePage");
  }, []);

  const getAllOutlets = async (from, shopId) => {
    console.log(from + " " + shopId);
    let allItems = await getItemsByTypeFunc(from, shopId);
    allItems.map((item) => {
      console.log(item.shopName);
      item.location = from == "Outlet" ? item.location : item.shopName;
    });
    setAllOutlet(allItems);
  };

  const getAllItemsByType = async (from, outletShopId) => {
    let tempOpening = 0;
    let tempClosing = 0;

    let allInvoice = await getInvoiceDateOVPagination(
      from == "Vendor" ? "PurchaseInvoice" : "SalesInvoice",
      outletShopId,
      outletId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );

    let payment = await getInvoiceDateOVPagination(
      from == "Vendor" ? "PurchasePayment" : "SalesPayment",
      outletShopId,
      outletId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );

    let temp = allInvoice.concat(payment);
    if (temp.length > 0) {
      temp = temp.sort(
        (a, b) =>
          moment(a.timestamp, "YYYY-MM-DD  HH:mm:ss") -
          moment(b.timestamp, "YYYY-MM-DD  HH:mm:ss")
      );

      temp.map((item) => {
        item.debit = 0;
        item.credit = 0;
        if (item.type == "SalesInvoice") item.debit = item.totalAmount;
        else if (item.type == "PurchasePayment")
          item.debit = item.installmentAmount;
        else if (item.type == "PurchaseInvoice") item.credit = item.totalAmount;
        else if (item.type == "SalesPayment")
          item.credit = item.installmentAmount;
      });

      let debitAmount = temp.reduce(
        (sum, value) => sum + parseFloat(value.debit),
        0
      );

      let creditAmount = temp.reduce(
        (sum, value) => sum + parseFloat(value.credit),
        0
      );

      if (temp[0].outletBalance != "" && temp[0].outletBalance != null) {
        if (temp[0].type == "SalesPayment")
          tempOpening = temp[0].outletBalance + temp[0].installmentAmount;
        else if (temp[0].type == "SalesInvoice")
          tempOpening = temp[0].outletBalance - temp[0].totalAmount;
        else if (temp[0].type == "PurchasePayment")
          tempOpening = temp[0].outletBalance - temp[0].installmentAmount;
        else if (temp[0].type == "PurchaseInvoice")
          tempOpening = temp[0].outletBalance + temp[0].totalAmount;
        tempClosing = tempOpening + debitAmount - creditAmount;
        setOpeningBlnc(tempOpening);
        setClosingBlnc(tempClosing);
      } else setDisplayBlnc(false);
    } else setDisplayBlnc(false);
    setAllInvoice(temp);
    setSpinnerState(false);
  };

  useEffect(() => {
    const getInvoice = async () => {
      setAllInvoice([]);
      setDisplayBlnc(true);
      setSpinnerState(true);
      await getAllItemsByType(from, outletShopId);
    };
    if (outletShopId != "" && from != "") getInvoice();
  }, [dateRange, location]);

  useEffect(() => {
    const getOutlet = async () => {
      setLocation("");
      await getAllOutlets(from, shopId);
    };
    if (shopId != "" && from != "") getOutlet();
  }, [from]);

  const downloadPDF = async () => {
    let date = `${dateRange[0].format("DD/MM/YY")}-${dateRange[1].format(
      "DD/MM/YY"
    )}`;
    await pdf(
      <InvoiceStmnt
        allInvoice={allInvoice}
        dateRange={date}
        location={location}
        openingBlnc={openingBlnc}
        closingBlnc={closingBlnc}
        displayBlnc={displayBlnc}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(
          blob,
          `Statement Report-${location}-${dateRange[0].format(
            "DD/MM/YY"
          )}-${dateRange[1].format("DD/MM/YY")}.pdf`
        );
      });
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Select
            style={{ backgroundColor: "white", width: "15%" }}
            value={from}
            onChange={(value) => {
              setFrom(value);
            }}
          >
            <Option value="">Select</Option>
            <Option value="Outlet">Outlet</Option>
            <Option value="Vendor">Vendor</Option>
            <Option value="Dealer">Dealer</Option>
          </Select>
          <Select
            style={{ backgroundColor: "white", width: "25%" }}
            value={location}
            showSearch
            onChange={(value) => {
              if (value == "") {
                setAllInvoice([]);
                setLocation("");
                setOutletId("");
              } else {
                let temp = JSON.parse(value);
                setOutletId(temp.id);
                setLocation(temp.location);
              }
            }}
          >
            <Option value="">Select {from}</Option>
            {allOutlets.map((value) => {
              return (
                <Option value={JSON.stringify(value)}>{value.location}</Option>
              );
            })}
          </Select>
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          {allInvoice.length > 0 && (
            <Button
              className="button"
              type="primary"
              onClick={() => downloadPDF()}
              style={{ width: 150 }}
            >
              Download PDF
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        {displayBlnc && (
          <div>
            <p style={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }}>
              Opening Balance: {openingBlnc.toFixed(2)}
            </p>
          </div>
        )}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={allInvoice}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {displayBlnc && (
          <div>
            <p style={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }}>
              Closing Balance: {closingBlnc.toFixed(2)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
