import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Row, Col, Button, message, Card, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc } from "./functionCall";
const { Option } = Select;

function AddOutlet(props) {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [outletType, setOutletType] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopId, setShopId] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [billPrefix, setBillPrefix] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [balance, setBalance] = useState(0);
  const [outletBalance, setOutletBalance] = useState(0);

  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          if (test.test != "addNewOutlet") getItemById();
          else await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("ManageOutlet")) navigate("/homePage");
    getUser();
    if (props.common.shopType === "master") {
      setShopName(props.common.shopName);
    }
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setLocation(res.location);
        setAddress(res.address);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setOutletType(res.outletType);
        setShopName(res.shopName);
        setCountry(res.country);
        setCurrency(res.currency);
        setBillPrefix(res.billPrefix);
        setName(res.name ?? "");
        setCategory(res.category ?? "");
        setBalance(res.balance ?? 0);
        setOutletBalance(res.outletBalance ?? 0);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByType = async (shopId) => {
    let temp = [];

    if (props.common.shopType != "root")
      try {
        const allTodos = await API.graphql({
          query: queries.getItemById,
          variables: {
            id: shopId,
          },
        });
        let res = allTodos.data.getItemById.location;
        temp.push(res.toLowerCase());
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    let allItems = await getItemsByTypeFunc(
      props.common.shopType === "root" ? "Company" : "Outlet",
      props.common.shopType === "root" ? "NA" : shopId
    );
    allItems.map((item) => {
      temp.push(
        props.common.shopType === "root"
          ? item.shopName.toLowerCase()
          : item.location.toLowerCase()
      );
    });
    setAllItems(temp);
  };

  const addItem = async () => {
    console.log(JSON.stringify(props.common.currencyArray));
    let randomId = Math.floor(Math.random() * 10000000000) + 1;
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          shopName: shopName,
          location: location,
          address: address,
          phoneNumber: "+91" + phoneNumber,
          outletType: outletType,
          shopId: shopId,
          currency: currency,
          country: country,
          randomId: randomId,
          addedBy: addedBy,
          type: props.common.shopType === "root" ? "Company" : "Outlet",
          shopType: props.common.shopType === "root" ? "master" : "outlet",
          billPrefix: billPrefix,
          formulaList:
            props.common.shopType === "root"
              ? JSON.stringify(props.common.calBasedOn)
              : JSON.stringify({}),
          symbol:
            props.common.shopType === "root"
              ? props.common.currencyArray[currency].symbol
              : "NA",
          name: name,
          category: category,
          balance: outletBalance,
          outletBalance: outletBalance,
        },
      });
      message.error(
        props.common.shopType === "root" ? "Company Added" : "Outlet Added"
      );
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          shopName: shopName,
          location: location,
          address: address,
          phoneNumber: "+91" + phoneNumber,
          outletType: outletType,
          country: country,
          currency: currency,
          updatedBy: updatedBy,
          billPrefix: billPrefix,
          symbol: props.common.currencyArray[currency]
            ? props.common.currencyArray[currency].symbol
            : "",
          name: name,
          category: category,
          balance: outletBalance,
          outletBalance: outletBalance,
        },
      });
      message.error(
        props.common.shopType === "root" ? "Company Updated" : "Outlet updated"
      );
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (props.common.shopType === "root" && shopName === "")
      return message.error("Please enter company name");
    if (
      props.common.shopType === "root" &&
      allItems.includes(shopName.toLowerCase())
    )
      return message.error("Company already exists");

    if (props.common.shopType === "master" && location === "")
      return message.error("Please enter location");
    if (props.common.shopType === "master" && name === "")
      return message.error("Please enter outlet name");
    if (props.common.shopType === "master" && category === "")
      return message.error("Please select company category");
    if (props.common.shopType === "master" && location === "")
      return message.error("Please enter location");
    if (
      props.common.shopType === "master" &&
      allItems.includes(location.toLowerCase())
    )
      return message.error("Location already exists");
    if (address === "") return message.error("Please enter address");
    if (phoneNumber === "") return message.error("Please enter phone number");
    if (props.common.shopType != "root" && outletType === "")
      return message.error("Select outlet type");
    if (props.common.shopType === "root" && country === "")
      return message.error("Please enter country name");
    if (props.common.shopType === "root" && currency === "")
      return message.error("Please enter currency");
    if (billPrefix == "")
      return message.error("Please enter prefix for bill number");
    if (billPrefix.length < 3)
      return message.error("Please enter billing prefix of 3 letters");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(phoneNumber) === false)
      return message.error("Please enter valid phone number");

    setSpinnerState("true");
    if (test.test === "addNewOutlet") addItem();
    else editItem();
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">
              {props.common.shopType === "root"
                ? "Enter Company Details"
                : "Enter Outlet Details"}
            </h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            {" "}
            <PlusOutlined />
            {props.common.shopType === "root"
              ? test.test === "addNewOutlet"
                ? "Add Company"
                : "Update Company"
              : test.test === "addNewOutlet"
              ? "Add Outlet"
              : "Update Outlet"}
          </Button>
        </Col>
        <div className="divider"></div>
      </Row>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          {props.common.shopType === "root" && (
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Company Name:
                </label>
                {(test.test === "addNewOutlet" && (
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Company Name"
                    value={shopName}
                    onChange={(e) => setShopName(e.target.value)}
                    maxLength={100}
                  />
                )) || (
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    {shopName}
                  </label>
                )}
              </div>
            </Col>
          )}
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Location:
              </label>
              {(test.test === "addNewOutlet" && (
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  maxLength={100}
                />
              )) || (
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  {location}
                </label>
              )}
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Address:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                maxLength={500}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Phone Number:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                maxLength={10}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {props.common.shopType !== "root" && (
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Select Shop Type:
                </label>
                <Select
                  style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                  value={outletType}
                  onChange={(value) => setOutletType(value)}
                >
                  <Option value="">Select Shop Type</Option>
                  <Option value={"Outlet"}>Outlet</Option>
                  <Option value={"Account"}>Account</Option>
                  <Option value={"Store"}>Store</Option>
                </Select>
              </div>
            </Col>
          )}
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Prefix for bill Number
              </label>

              {test.test === "addNewOutlet" ? (
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Prefix for bill Number"
                  value={billPrefix}
                  onChange={(e) => setBillPrefix(e.target.value)}
                  maxLength={3}
                />
              ) : (
                <label
                  style={{
                    width: "100%",
                    flex: 1,
                  }}
                >
                  {billPrefix}
                </label>
              )}
            </div>
          </Col>
        </Row>
        {props.common.shopType === "root" && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Country Name:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Country Name"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  maxLength={100}
                />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Currency:
                </label>
                <Select
                  style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                  value={currency}
                  onChange={(value) => {
                    setCurrency(value);
                  }}
                >
                  <Option value="">Select Currency</Option>
                  {Object.keys(props.common.currencyArray).map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Company/Outlet Name:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Company Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={100}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Category:
              </label>
              <Select
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                value={category}
                onChange={(value) => {
                  setCategory(value);
                }}
              >
                <Option value="">Select Category</Option>
                {props.common.outletCategoryArray.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Opening Balance:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Balance"
                value={outletBalance}
                onChange={(e) => setOutletBalance(e.target.value)}
                maxLength={100}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Current Balance:
              </label>
              {balance}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddOutlet);
