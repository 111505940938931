import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Row, Col, Button, message, Card, Select, Input } from "antd";
import { TextField } from "@mui/material";
import { getItemsByOutletPagination } from "./functionCall";
const { Option } = Select;

function AddVendor(props) {
  const navigate = useNavigate();
  const [shopName, setShopName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [GSTNumber, setGSTNumber] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [balance, setBalance] = useState(0);
  const [outletBalance, setOutletBalance] = useState(0);

  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewVendor") getItemById();
          else await getItemsByOutlet(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (props.common.shopType === "root") navigate("/homePage");
    getUser();
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      // console.log(allTodos.data.getItemById)
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setShopName(res.shopName);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setAddress(res.address);
        setGSTNumber(res.GSTNumber);
        setCategory(res.category ?? "");
        setName(res.name ?? "");
        setState(res.state ?? "");
        setCity(res.city ?? "");
        setBalance(res.balance ?? 0);
        setOutletBalance(res.outletBalance ?? 0);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByOutlet = async (outletShopId) => {
    let allItems = await getItemsByOutletPagination(
      test.from == "vendor" ? "Vendor" : "Dealer",
      "live",
      outletShopId
    );
    let temp = [];
    allItems.map((item) => {
      temp.push(item.shopName.toLowerCase());
    });
    setAllItems(temp);
  };

  const addItem = async () => {
    let randomId = Math.floor(Math.random() * 10000000000) + 1;
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          shopName: shopName,
          phoneNumber: "+91" + phoneNumber,
          address: address,
          GSTNumber: GSTNumber,
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: randomId,
          addedBy: addedBy,
          type: test.from == "vendor" ? "Vendor" : "Dealer",
          category: category,
          name: name,
          state: state,
          city: city,
          balance: outletBalance,
          outletBalance: outletBalance,
        },
      });
      message.error(test.from == "vendor" ? "Vendor" : "Dealer" + " Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          shopName: shopName,
          phoneNumber: "+91" + phoneNumber,
          address: address,
          GSTNumber: GSTNumber,
          updatedBy: updatedBy,
          category: category,
          name: name,
          state: state,
          city: city,
          balance: outletBalance,
          outletBalance: outletBalance,
        },
      });
      message.error(test.from == "vendor" ? "Vendor" : "Dealer" + " updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (shopName === "") return message.error("Please enter company name");
    if (allItems.includes(shopName.toLowerCase()))
      return message.error(
        test.from == "vendor" ? "Vendor" : "Dealer" + "already exists"
      );
    if (phoneNumber === "") return message.error("Please enter phone number");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(phoneNumber) === false)
      return message.error("Please enter valid phone number");
    if (category === "") return message.error("Please select vendor category");
    setSpinnerState("true");
    if (test.test === "addNewVendor") addItem();
    else editItem();
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <h1 className="bill-heading">
              Enter {test.from == "vendor" ? "Vendor" : "Dealer"} Details
            </h1>
          </Col>
          <Col style={{ paddingRight: 20 }}>
            <Button
              className="button"
              type="primary"
              disabled={spinnerState}
              onClick={() => checkInput()}
            >
              {test.test === "addNewVendor"
                ? test.from == "vendor"
                  ? "Add Vendor"
                  : "Add Dealer"
                : test.from == "vendor"
                ? "Update Vendor"
                : "Update Dealer"}
            </Button>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Card style={{ width: "100%", marginLeft: 20, marginRight: 20 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <TextField
                  label="Name"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "white" }}
                  margin="normal"
                  value={shopName}
                  size="small"
                  onChange={(e) => setShopName(e.target.value)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  autoComplete="off"
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  style={{ width: "70%", backgroundColor: "white" }}
                  margin="normal"
                  value={phoneNumber}
                  size="small"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  inputProps={{
                    maxLength: 10,
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <TextField
                  label="Address"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "white" }}
                  margin="normal"
                  value={address}
                  size="small"
                  onChange={(e) => setAddress(e.target.value)}
                  inputProps={{
                    maxLength: 500,
                  }}
                  autoComplete="off"
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  label="GST Number"
                  variant="outlined"
                  style={{ width: "70%", backgroundColor: "white" }}
                  margin="normal"
                  size="small"
                  value={GSTNumber}
                  onChange={(e) => setGSTNumber(e.target.value)}
                  inputProps={{
                    maxLength: 20,
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <Select
                  style={{ width: "80%", backgroundColor: "white" }}
                  value={category}
                  onChange={(value) => {
                    setCategory(value);
                  }}
                >
                  <Option value="">Select Category</Option>
                  {(test.from == "vendor"
                    ? props.common.purchaseCatArray
                    : props.common.dealerCatArray
                  ).map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={12}>
                <Select
                  showSearch
                  style={{ width: "70%", backgroundColor: "white" }}
                  value={state}
                  onChange={(value) => {
                    setState(value);
                  }}
                >
                  <Option value="">Select State</Option>
                  {props.common.indianStatesAndUTs.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <TextField
                  label="Contact Person Name"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "white" }}
                  margin="normal"
                  value={name}
                  size="small"
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  autoComplete="off"
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  label="City"
                  variant="outlined"
                  style={{ width: "70%", backgroundColor: "white" }}
                  margin="normal"
                  value={city}
                  size="small"
                  onChange={(e) => setCity(e.target.value)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Opening Balance:
                  </label>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Opening Balance"
                    value={outletBalance}
                    onChange={(e) => setOutletBalance(e.target.value)}
                    maxLength={100}
                  />
                </div>
              </Col>
              <Col xs={24} sm={10} offset={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Current Balance:
                  </label>
                  <label>{balance}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
