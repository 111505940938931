import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, DatePicker, Select, Button } from "antd";
import { getItemsdatePagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as queries from "../graphql/queries";

function ReportExpense(props) {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [reportList, setReportList] = useState([]);
  const [bankName, setBankName] = useState("");
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [bankBlnc, setBankBlnc] = useState({});
  const [openingBalance, setOpeningBalance] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const [displayBlnc, setDisplayBlnc] = useState(true);

  const headers = [
    { label: "Added Date", key: "addedDate" },
    { label: "Date", key: "date" },
    { label: "Bank Name", key: "bankName" },
    { label: "Type", key: "type" },
    { label: "From", key: "from" },
    { label: "Credit", key: "credit" },
    { label: "Debit", key: "debit" },
  ];

  const footer = [
    { addedDate: "Opening Balance", date: openingBalance },
    { addedDate: "Closing Balance", date: closingBalance },
  ];

  const fullData = [...footer, ...reportList];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          getAllItemsByType(user.attributes["custom:outletShopId"]);
          await getItemById(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportBank")) navigate("/homePage");
  }, []);

  const getItemById = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res = allTodos.data.getItemById;
      setBankBlnc(res.bankBlnc ? JSON.parse(res.bankBlnc) : {});
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getAllItemsByType = async (outletShopId) => {
    let temp = [];
    let Expense = await getItemsdatePagination(
      "Expense",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let SalesPayment = await getItemsdatePagination(
      "SalesPayment",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let PurchasePayment = await getItemsdatePagination(
      "PurchasePayment",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    temp = Expense.concat(SalesPayment).concat(PurchasePayment);
    temp.map((item) => {
      if (item.bankName == "" && item.paymentMode == "Cash")
        item.bankName = "Cash";
    });
    if (bankName != "") temp = temp.filter((item) => item.bankName == bankName);
    temp.map((item) => {
      item.from = item.location ?? item.vendorName ?? item.itemDescription;
      item.credit = item.type == "SalesPayment" ? item.installmentAmount : "";
      item.debit =
        item.type != "SalesPayment"
          ? item.installmentAmount ?? item.totalAmount
          : "";
    });
    // temp = temp.sort((a, b) => {
    //   const dateA = new Date(a.date);
    //   const dateB = new Date(b.date);
    //   return dateA - dateB;
    // });

    temp = temp.sort(
      (a, b) =>
        moment(a.timestamp, "YYYY-MM-DD  HH:mm:ss") -
        moment(b.timestamp, "YYYY-MM-DD  HH:mm:ss")
    );

    setReportList(temp);
    console.log(JSON.stringify(temp));
    if (bankName != "") {
      let openingBlnc = 0;
      let closingBlnc = 0;
      if (temp.length > 0) {
        openingBlnc =
          temp[0].type != "SalesPayment"
            ? temp[0].balance +
              (temp[0].installmentAmount ?? temp[0].totalAmount)
            : temp[0].balance - temp[0].installmentAmount;
        closingBlnc = temp[temp.length - 1].balance;
        let flag = true;
        temp.map((item) => {
          if (item.balance == null) flag = false;
        });
        setDisplayBlnc(flag);
        console.log(temp[temp.length - 1].balance);
      }
      setOpeningBalance(openingBlnc.toFixed(2));
      setClosingBalance(closingBlnc.toFixed(2));
    }

    setSpinnerState(false);
  };

  useEffect(() => {
    if (outletShopId != "") {
      setReportList([]);
      setSpinnerState(true);
      getAllItemsByType(outletShopId);
    }
  }, [dateRange, bankName]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          // width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{ marginBottom: 20, display: "flex", alignContent: "center" }}
        >
          <p style={{ marginRight: 20 }}>Bank Name</p>
          <Select
            style={{ width: 250, marginTop: 5 }}
            value={bankName}
            onChange={(value) => {
              setBankName(value);
            }}
          >
            <Option value="">Select a bank</Option>
            {props.common.bankList.map((item) => {
              return <Option value={item}>{item}</Option>;
            })}
          </Select>
          <p style={{ marginRight: 20, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%", marginRight: 20 }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          <Button type="primary">
            <CSVLink
              data={bankName != "" && displayBlnc ? fullData : reportList}
              headers={headers}
              filename={
                "Report Bank-" +
                dateRange[0].format("DD-MMM-YYYY") +
                "-" +
                dateRange[1].format("DD-MMM-YYYY") +
                ".csv"
              }
            >
              Export to CSV
            </CSVLink>
          </Button>
        </div>
        <h2>
          Bank transaction between {dateRange[0].format("DD MMM YY")}
          {" - "}
          {dateRange[1].format("DD MMM YY")}
        </h2>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              {bankName != "" && displayBlnc && (
                <p style={{ textAlign: "right", fontWeight: "bold" }}>
                  Opening Balance {openingBalance}
                </p>
              )}
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ width: 150 }}>Added Date</p>
                  <p style={{ width: 150 }}>Date</p>
                  <p style={{ width: 150 }}>Bank Name</p>
                  <p style={{ width: 150 }}>Type</p>
                  <p style={{ width: 150 }}>From</p>
                  <p
                    style={{
                      width: 150,
                      color: "Green",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    Credit
                  </p>
                  <p
                    style={{
                      width: 150,
                      color: "Red",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    Debit
                  </p>
                </div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <p style={{ width: 150 }}>
                          {moment(item.addedDate).format("DD MMM YY")}
                        </p>
                        <p style={{ width: 150 }}>
                          {moment(item.date).format("DD MMM YY")}
                        </p>
                        <p style={{ width: 150 }}>{item.bankName}</p>
                        <p style={{ width: 150 }}>{item.type}</p>
                        <p style={{ width: 150 }}>{item.from}</p>
                        <p
                          style={{
                            width: 150,
                            color: "Green",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {item.credit}
                        </p>
                        <p
                          style={{
                            width: 150,
                            color: "Red",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {item.debit}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
              {bankName != "" && displayBlnc && (
                <p style={{ textAlign: "right", fontWeight: "bold" }}>
                  Closing Balance {closingBalance}
                </p>
              )}
            </Card>
          </Col>
          <Col>
            <div style={{ marginLeft: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                }}
              >
                <p style={{ width: 150 }}>Bank Name</p>
                <p style={{ width: 120 }}>Current Balance</p>
              </div>
              {Object.entries(bankBlnc).map(([key, value]) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <p style={{ width: 150 }}>{value.bankName}</p>
                    <p
                      style={{
                        width: 120,
                        textAlign: "right",
                        marginRight: 20,
                      }}
                    >
                      {value.currentBalance.toFixed(2)}
                    </p>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                }}
              >
                <p style={{ width: 150 }}>Total</p>
                <p style={{ width: 120, textAlign: "right", marginRight: 20 }}>
                  {Object.values(bankBlnc)
                    .reduce((sum, bill) => sum + bill.currentBalance, 0)
                    .toFixed(2)}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
